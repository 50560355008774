<template>
  <BaseLayout>
    <template #header>
      <h1>Detail Pengiriman Labu Darah</h1>
      <p class="text-black-100 mb-0">Pengirim : {{ getSenderSummary }}</p>
      <p class="text-black-100 mb-0">Penerima : {{ getReceiverSummary }}</p>
      <p class="text-black-100">Status : {{ getReceivedSummary }}</p>
      <p class="text-black-100 mb-0">MU : {{ $route.params.mu_name }}</p>
      <p class="text-black-100 mb-0">Tanggal MU : {{ $route.params.date }}</p>
    </template>
    <template #content>
      <b-row class="d-flex justify-content-center">
        <b-col cols="auto">
          <div class="table-wrapper w-100 mx-auto overflow-scroll">
            <b-table
              class="table-borderless table-responsive w-100"
              :fields="fields"
              :busy.sync="isBusy"
              :items="items"
              show-empty
            >
              <template #empty>
                <div class="hp-p1-body text-center my-2">
                  <strong>Tidak Ada Data</strong>
                </div>
              </template>
              <template #table-busy>
                <div class="hp-p1-body text-center my-2">
                  <b-spinner class="align-middle mr-8" />
                  <strong>Memuat...</strong>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </template>
  </BaseLayout>
</template>

<script>
import { BCol, BRow, BSpinner, BTable } from "bootstrap-vue";
import manageBloodShipmentAPI from "../../../../../api/blood_shipment/manageBloodShipmentAPI";

export default {
  name: "DetailBloodShipment",
  components: {
    BRow,
    BTable,
    BCol,
    BSpinner,
  },
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "barcode", label: "Barcode", sortable: true },
        { key: "blood_bag_number", label: "Nomor Selang", sortable: true },
        { key: "blood_type", label: "Golongan Darah", sortable: true },
        { key: "bag_type", label: "Jenis Kantong", sortable: true },
      ],
    };
  },
  computed: {
    getSenderSummary() {
      const uniqueSenders = new Set(this.items.map((item) => item.sender));
      return [...uniqueSenders].join(", ") || "Tidak Ada Pengirim";
    },
    getReceiverSummary() {
      const uniqueReceivers = new Set(
        this.items.map((item) => item.receiver || "Belum Ada Penerima")
      );
      return [...uniqueReceivers].join(", ");
    },
    getReceivedSummary() {
      const uniqueStatus = new Set(
        this.items.map((item) =>
          item.blood_received === 1 ? "Sudah Diterima" : "Belum Diterima"
        )
      );
      return [...uniqueStatus].join(", ");
    },
  },
  mounted() {
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      const params = {
        date: this.$route.params.date,
        mu_name: this.$route.params.mu_name,
        division: this.$route.params.division,
      };

      this.isBusy = true;
      try {
        const response = await manageBloodShipmentAPI.detailShipment(params);
        this.items = response.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 400px;
  overflow-y: auto;
}
</style>
